import '../sass/frontend.scss';
import $ from 'jquery';
import 'select2';
import Inputmask from 'inputmask';
import 'intl-tel-input';
import countryFieldChanges from './components/countryFieldChanges';
function ready() {
    countryFieldChanges();
    $('.js-input-select').select2();
    $('#date-of-birth').each(function () {
        Inputmask('99/99/9999').mask(this);
    });
    const clickOutHeaderLang = (clickedElem) => {
        const headerLanguages = window.document.querySelector('.js-header-languages');
        const itsLang = clickedElem == headerLanguages || headerLanguages.contains(clickedElem);
        const langIsActive = headerLanguages.classList.contains('active');
        if (!itsLang && langIsActive) {
            headerLanguages.classList.remove('active');
        }
    };
    document.body.addEventListener('click', (event) => {
        const target = event.target;
        const ROLE = target.dataset.role;
        clickOutHeaderLang(target);
        switch (ROLE) {
            case 'toggle-accordion-item':
                {
                    event.preventDefault();
                    const faqItems = window.document.querySelectorAll('.js-faq-item');
                    const parentItem = target.closest('.js-faq-item');
                    const actionType = parentItem && parentItem.classList.contains('active') ? 'remove' : 'add';
                    if (!parentItem || !faqItems)
                        return;
                    [...faqItems].forEach((item) => {
                        item.classList.remove('active');
                    });
                    parentItem.classList[actionType]('active');
                }
                break;
            case 'toggle-burger-menu':
                {
                    event.preventDefault();
                    const burgerMenu = window.document.querySelector('.js-burger-menu');
                    const actionType = burgerMenu && burgerMenu.classList.contains('active') ? 'remove' : 'add';
                    if (!burgerMenu && !actionType)
                        return;
                    burgerMenu.classList[actionType]('active');
                    window.document.body.classList.toggle('opened-burger-menu');
                }
                break;
            case 'toggle-header-languages':
                {
                    event.preventDefault();
                    const headerLanguages = window.document.querySelector('.js-header-languages');
                    const actionType = (headerLanguages && headerLanguages.classList.contains('active')) ? 'remove' : 'add';
                    if (!headerLanguages)
                        return;
                    headerLanguages.classList[actionType]('active');
                }
                break;
            default:
                break;
        }
    });
}
window.document.addEventListener('DOMContentLoaded', ready);
