const countryFieldChanges = () => {
    setTimeout(() => {
        const countryTextWrapper = document.querySelector('.js-countrytext-wrapper');
        const contactLangInput = document.querySelector('#contact-lang');
        const countryList = countryTextWrapper && countryTextWrapper.querySelectorAll('.country-list .country');
        const languageReplacementStructure = {
            'en-US': {
                us: 'English',
                de: 'German',
                ru: 'Russian',
                ua: 'Ukrainian',
                pl: 'Polish',
            },
            'de-DE': {
                us: 'Englisch',
                de: 'Deutsch',
                ru: 'Russisch',
                ua: 'Ukrainisch',
                pl: 'Polieren',
            },
            'ru-RU': {
                us: 'Английский',
                de: 'Немецкий',
                ru: 'Русский',
                ua: 'Украинский',
                pl: 'Польский',
            },
            uk: {
                us: 'Англійська',
                de: 'Німецька',
                ru: 'Російська',
                ua: 'Українська',
                pl: 'Польська',
            },
            'pl-PL': {
                us: 'Angielski',
                de: 'Niemiecki',
                ru: 'Rosyjski',
                ua: 'Ukraiński',
                pl: 'Polski',
            },
        };
        const htmlElement = document.documentElement;
        const docLang = htmlElement.getAttribute('lang');
        countryList &&
            countryList.forEach((item) => {
                const countryCode = item.getAttribute('data-country-code');
                const countryName = item.querySelector('.country-name');
                countryName.innerHTML = languageReplacementStructure[docLang][countryCode];
            });
        const selectedFlag = document.querySelector('.js-countrytext-wrapper .selected-flag');
        if (!selectedFlag)
            return;
        const flagDropdown = selectedFlag.closest('.flag-dropdown');
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'title') {
                    const activeCountry = flagDropdown.querySelector('.country.active');
                    const code = activeCountry.getAttribute('data-country-code');
                    contactLangInput.value = languageReplacementStructure[docLang][code];
                }
            });
        });
        const activeCountry = flagDropdown && flagDropdown.querySelector('.country.active');
        const code = activeCountry.getAttribute('data-country-code');
        contactLangInput.value = languageReplacementStructure[docLang][code];
        observer.observe(selectedFlag, {
            attributes: true,
        });
    }, 500);
};
export default countryFieldChanges;
